$(function () {
    $('.references-carousel').slick({
        lazyLoad: 'ondemand',
        arrows: true,
        autoplay: true,
        autoplaySpeed: 4000,
        speed: 500,
        slidesToShow: 6,
        slidesToScroll: 1,
        responsive: [{
            breakpoint: 1500,
            settings: {
                slidesToShow: 4,
                slidesToScroll: 1,
                infinite: true,
                arrows: true
            }
        },
        {
            breakpoint: 1000,
            settings: {
                slidesToShow: 3,
                slidesToScroll: 1
            }
        },
        {
            breakpoint: 700,
            settings: {
                slidesToShow: 2,
                slidesToScroll: 1
            }
        }
        ]
    });

    wow = new WOW(
        {
            animateClass: 'animated',
            offset: 100,
            callback: function (box) {
                console.log("WOW: animating <" + box.tagName.toLowerCase() + ">")
            }
        }
    );
    wow.init();
    
    $('.member img').click(function () {
        var slug = $(this).attr('data-slug');
        $.ajax({
            url: '/' + slug,
            dataType: 'html',
            success: function (data, statut) {
                swal.fire({
                    html: data,
                    focusConfirm: false,
                    showConfirmButton: false,
                    showCloseButton: true,
                    width: 1000
                })
            },

            error: function (resultat, statut, erreur) {
                console.log('erreur ajax');
            }
        });
    });



    $('.metiers-carousel').slick({
        lazyLoad: 'ondemand',
        dots: true,
        arrows: false,
        autoplay: true,
        autoplaySpeed: 4000,
        speed: 500,
        slidesToShow: 3,
        slidesToScroll: 1,
        responsive: [{
            breakpoint: 1200,
            settings: {
                slidesToShow: 2,
                slidesToScroll: 1,
                infinite: true,
                arrows: false
            }
        },
        {
            breakpoint: 600,
            settings: {
                slidesToShow: 1,
                slidesToScroll: 1,
                arrows: false
            }
        }
        ]
    });

    $('.hamburger').click(function () {
        $(this).toggleClass('is-active');
        $('.bg-mobile-menu').toggleClass('w-100');
        $('body').toggleClass('overflow-body');
        $('.navbar-expand-xl').toggleClass('m-open');
        $('.nav-link').toggleClass('animate__animated animate__fadeInLeft');
    });

    $('.nav-item a').click(function () {
        if ($('.m-open').length) {
            $('body').removeClass('overflow-body');
            $('.bg-mobile-menu').removeClass('w-100');
            $('.hamburger').removeClass('is-active');
            $('.m-open').removeClass('m-open');
        }
    });

    $('.metiers-carousel img').click(function(){
        var imgurl = $(this).attr('src');
        var imgalt = $(this).attr('alt');
        swal.fire({
            width:1000,
            imageUrl: imgurl,
            imageAlt: imgalt,
            imgHeight:800,
            showConfirmButton: false,
            showCloseButton: true,
            showClass: {
                popup: 'animate__animated animate__fadeIn faster'
              },
              hideClass: {
                popup: 'animate__animated animate__fadeOut faster'
              }
        });
    });
    
});