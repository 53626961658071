require('./bootstrap');
require('slick-carousel');
window.swal = require('sweetalert2');
require('hamburgers');

// require('jquery-mousewheel');
// require('malihu-custom-scrollbar-plugin');

require('./wow.min.js');

require('./main.js');
